import React, { useState, useEffect } from 'react'
import './typewriter.css'

const firstLines = ['PROGRAMO SIN', 'CUENTA CUENTOS']
const secondLines = ['PUNTO Y COMA', 'EMPEDERNIDO']

/**
 * Typewriter Component. Create a component that changes between two texts with a typewriter effect
 * @returns TypeWriter as JSX Component
 */
const TypeWriter = ({ canStart }) => {
  // State
  const [firstLine, setFirstLine] = useState('')
  const [secondLine, setSecondLine] = useState('')
  const [actualDescription, setActualDescription] = useState(0)
  const [actualLine, setActualLine] = useState(0)
  const [direction, setDirection] = useState(0) // ascending = 0, descending = 1
  const [timeLock, setTimeLock] = useState(0)
  const [changeHandler, setChangeHanlder] = useState(true)

  /**
   * Create the typewriter animation
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (canStart) {
        if (direction === 0) {
          if (firstLine.length < firstLines[actualDescription].length) {
            setFirstLine(firstLine + firstLines[actualDescription][firstLine.length])
          } else if (secondLine.length < secondLines[actualDescription].length) {
            setSecondLine(secondLine + secondLines[actualDescription][secondLine.length])
            if (actualLine === 0) setActualLine(1)
          } else if (timeLock < 22) {
            setTimeLock(timeLock + 1)
          } else {
            setTimeLock(0)
            setDirection(1)
          }
        } else {
          if (secondLine.length > 0) {
            setSecondLine(secondLine.slice(0, -1))
          } else if (firstLine.length > 0) {
            setFirstLine(firstLine.slice(0, -1))
            if (actualLine === 1) setActualLine(0)
          } else if (timeLock < 6) {
            setTimeLock(timeLock + 1)
          } else {
            setTimeLock(0)
            setDirection(0)
            setActualDescription((actualDescription + 1) % 2)
          }
        }
        setChangeHanlder(!changeHandler)
      }
    }, 100)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [canStart, changeHandler])

  return (<h1 className='gl-typewriter'>{firstLine}<span className={'gl-typewriter__underscore ' + ((actualLine === 1) ? 'gl-typewriter__underscore--invisible ' : ' ') + ((actualDescription === 0) ? 'gl-typewriter__underscore--green' : 'gl-typewriter__underscore--blue')}>_</span> <br />{secondLine}<span className={'gl-typewriter__underscore ' + ((actualLine === 0) ? 'gl-typewriter__underscore--invisible ' : ' ') + ((actualDescription === 0) ? 'gl-typewriter__underscore--green' : 'gl-typewriter__underscore--blue')}>_</span></h1>)
}

export default TypeWriter
