import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import MainView from '../parts/main-view/main-view'
import Categories from '../parts/categories/categories'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || 'Gerson Lázaro'
  const storiesUrl = data.stories?.edges[0]?.node?.fields?.slug || '#'
  const techUrl = data.tech?.edges[0]?.node?.fields?.slug || '#'
  const [animationCompleted, setAnimationCompleted] = useState(false)
  return (
    <>
      <Layout location={location} title={siteTitle} onAnimationComplete={setAnimationCompleted}>
        <Seo
          title='Gerson Lázaro'
          description='Desarrollador de software y contador de historias'
          url={location?.href}
          image="https://www.gersonlazaro.com/logo-gerson-lazaro.png"
          lang="es"
        />
        <MainView animationCompleted={animationCompleted} />
        <Categories techUrl={techUrl} storiesUrl={storiesUrl} />
      </Layout>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    tech: allMarkdownRemark(
      limit: 1
      sort: {order: DESC, fields: frontmatter___date}
      filter: {frontmatter: {date: {}, category: {eq: "tech"}}}
    ) {
      edges {
        node {
          fields {
            slug
          }
        }
      }
    }
    stories: allMarkdownRemark(
      limit: 1
      sort: {order: DESC, fields: frontmatter___date}
      filter: {frontmatter: {date: {}, category: {eq: "stories"}}}
    ) {
      edges {
        node {
          fields {
            slug
          }
        }
      }
    }
  }
`
