import * as React from 'react'
import Lines from '../../components/lines/lines'
import TypeWriter from '../../components/typewriter/typewriter'
import Button from '../../components/button/button'
import './main-view.css'

/**
 * MainView Component. Create the home page
 * @returns MainView as JSX Component
 */
const MainView = ({ animationCompleted }) => {
  const smoothScroll = () => {
    const element = document.getElementById('categories')
    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <div className='gl-main-view' id='mainView'>
      <Lines location='top' />
      <div className='gl-main-view__information'>
        <h2 className='gl-main-view__nickname'><span className='gl-main-view__nickname gl-main-view__nickname--italic'>JAVASCRIPT</span>·MANIÁTICO</h2>
        <TypeWriter canStart={animationCompleted} />
        <Button text='VER MÁS' color='gradient' fillMode='fill' handleClick={smoothScroll} />
      </div>
      <Lines location='bottom' />
    </div>
  )
}

export default MainView
