import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Lines from '../../components/lines/lines'
import { isBrowser } from '../../utils/utils'
import './categories.css'

/**
 * Categories Component. Create the divider view between stories and tech articles
 * @param {Object} props - Object with these properties:
 *  @param {String} techUrl - slug to newest post about tech
 *  @param {String} storiesUrl - slug to newest post about stories
 * @returns Categories as JSX Component
 */
const Categories = ({ techUrl, storiesUrl }) => {
  const prevPath = isBrowser() ? window.location.pathname : ''
  return (
    <div className='gl-categories' id='categories'>
      <Link to={techUrl} className='gl-category gl-category--left' state={{ prevPath: prevPath }}>
        <Lines location='top' />
        <h1 className='gl-category__number'>01</h1>
        <h1 className='gl-category__title'>ARTÍCULOS<br />DEV</h1>
        <Lines location='bottom' />
      </Link>
      <div className='gl-category__hover gl-category__hover--left' />
      <Link to={storiesUrl} className='gl-category gl-category--right' state={{ prevPath: prevPath }}>
        <Lines location='top' />
        <h1 className='gl-category__number'>10</h1>
        <h1 className='gl-category__title'>ESCRITOS<br />Y RELATOS</h1>
        <Lines location='bottom' />
      </Link>
      <div className='gl-category__hover gl-category__hover--right' />
    </div>
  )
}

Categories.propTypes = {
  techUrl: PropTypes.string.isRequired,
  storiesUrl: PropTypes.string.isRequired
}

export default Categories
